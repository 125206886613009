/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  VOUCHER_BY_SALES,
  VOUCHER_BY_SALES_PERIOD,
  TIER_COMMISSION_SUMMARY,
  SELECTED_COMM_PERIOD,
  VOUCHER_BY_SALES_CLEAR,
  SALES_PERIODS,
  SELECTED_SALES_TEAM_MEMBER,
  SELECTED_SALES_PERIOD,
  MTR_SALES_SUMMARY,
  CLEAR_SALES_SUMMARY,
  MTR_NAVIGATATION_KEY_DATA,
  ASSOCIATED_MEMBERS,
} from '../actions/insightQoreAction';
import { aboveQuotaGrowthRateRule, underPerformingGrowthRateRule, concernGrowthRateRule, unremittedGrowthRateRule } from '../views/sales/commission-voucher/rules/TierBuckectsCalc';


const initialState = {
  salesFilterData: [],
  salesPersonList: [],
  salesPeriodList: [],
  tieredCommissionList: [],
  isLoadingData: false,
  selectedCommPeriod: null,
  mtrNavigationData: null,

  commissionVoucherDataGrowthRateGreen: [],
  commissionVoucherDataGrowthRateYellow: [],
  commissionVoucherDataGrowthRateRed: [],
  commissionVoucherDataGrowthRateUnRemitted: [],
  commissionVoucherDataGrowthRateFull: [],

  shared_commission_voucher_data: [],
  shared_sales_team: [],
  shared_sales_periods: [],
  shared_selected_sales_team_member: '',
  shared_selected_sales_period: '',

  shared_mtr_sales_summary_data: [],
  shared_mtr_sales_summary_80data: [],
  shared_mtr_sales_summary_20data: [],

  associated_members: [],
  team_member_override: null
};

const insightQoreReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MTR_NAVIGATATION_KEY_DATA: {
      const navData = payload;
      return produce(state, draft => {
        draft.mtrNavigationData = navData;
      });
    }

    case VOUCHER_BY_SALES_CLEAR: {
      return produce(state, draft => {
        draft.commissionVoucherDataGrowthRateFull = [];
        draft.commissionVoucherDataGrowthRateGreen = [];
        draft.commissionVoucherDataGrowthRateYellow = [];
        draft.commissionVoucherDataGrowthRateRed = [];
        draft.commissionVoucherDataGrowthRateUnRemitted = [];

      });
    }
    case VOUCHER_BY_SALES: {

      const selectedSalesPeriod = payload.period;
      const initialResponse = payload.salesData;

      const greenGrowthRate = aboveQuotaGrowthRateRule(initialResponse, selectedSalesPeriod.attributes);
      const amberGrowthRate = underPerformingGrowthRateRule(initialResponse, selectedSalesPeriod.attributes);
      const redGrowthRate = concernGrowthRateRule(initialResponse, selectedSalesPeriod.attributes);
      const unremittedGrowthRate = unremittedGrowthRateRule(initialResponse, selectedSalesPeriod.attributes);


      return produce(state, draft => {
        //    draft.salesFilterData = salesFilterData;
        draft.selectedCommPeriod = selectedSalesPeriod;
        draft.commissionVoucherDataGrowthRateFull = initialResponse;
        draft.commissionVoucherDataGrowthRateGreen = greenGrowthRate;
        draft.commissionVoucherDataGrowthRateYellow = amberGrowthRate;
        draft.commissionVoucherDataGrowthRateRed = redGrowthRate;
        draft.commissionVoucherDataGrowthRateUnRemitted = unremittedGrowthRate;
      });
    }

    case VOUCHER_BY_SALES_PERIOD: {
      const commissionVoucherDataWithSalesMembers = payload;

      return produce(state, draft => {

        let mergedMembers = commissionVoucherDataWithSalesMembers.uniqueSalesRep.concat(commissionVoucherDataWithSalesMembers.filteredAssociated).concat(commissionVoucherDataWithSalesMembers.bdeFilteredAssociated);

        draft.salesFilterData =
          commissionVoucherDataWithSalesMembers.salesCommissionData;

        draft.salesPersonList =
          mergedMembers;

        draft.shared_commission_voucher_data =
          commissionVoucherDataWithSalesMembers.salesCommissionData;




        draft.shared_sales_team = mergedMembers;
      });
    }

    case TIER_COMMISSION_SUMMARY: {
      const tieredCommissionList = payload;
      return produce(state, draft => {
        draft.tieredCommissionList = tieredCommissionList;
      });
    }
    case SELECTED_COMM_PERIOD: {
      return produce(state, draft => {
        draft.selectedCommPeriod = payload;
      });
    }
    case SELECTED_SALES_TEAM_MEMBER: {
      return produce(state, draft => {
        draft.shared_selected_sales_team_member = payload;
      });
    }

    case SALES_PERIODS: {
      return produce(state, draft => {
        draft.shared_sales_periods = payload;
      });
    }

    case SELECTED_SALES_PERIOD: {
      return produce(state, draft => {
        draft.shared_selected_sales_period = payload;
      });
    }

    case MTR_SALES_SUMMARY: {
      return produce(state, draft => {
        draft.shared_mtr_sales_summary_data = payload.result;
        draft.shared_mtr_sales_summary_80data = payload.grossProfit80Data;
        draft.shared_mtr_sales_summary_20data = payload.grossProfit20Data;
      });
    }

    case CLEAR_SALES_SUMMARY: {
      return produce(state, draft => {
        draft.shared_mtr_sales_summary_data = [];
        draft.shared_mtr_sales_summary_80data = [];
        draft.shared_mtr_sales_summary_20data = [];
      });
    }

    case ASSOCIATED_MEMBERS: {
      return produce(state, draft => {
        draft.associated_members = payload;

        if (payload.length > 0) {
          draft.team_member_override = payload[0];
        }

      });
    }


    default: {
      return state;
    }
  }
};

export default insightQoreReducer;
