import React from 'react';
import { Box } from '@material-ui/core';
// import Support from './Support';

export default function TopBarAddOn() {
  return (
    <Box display="inline-flex" alignItems="center">

      {/* <Support /> */}
    </Box>
  )
}
