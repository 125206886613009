import find from 'lodash/find';

export const ROLES_DEV = {
  INSIGHT_QORE: {
    ADMIN: {
      id: 230
    },
    TEAM_MEMBER: {
      id: 231
    },
    TEAM_MANAGER: {
      id: 232
    },
    ACCOUNT_ASSISTANT: {
      id: 233
    },
    CRO: {
      id: 234
    },
    SALES_ACCOUNT_EXECUTIVE: {
      id: 245
    },
    KEY_ACCOUNT_EXECUTIVE: {
      id: 246
    },
    CUSTOMER_SUCCESS_EXECUTIVE: {
      id: 247
    },
    BUSINESS_DEVELOPMENT_EXECUTIVE: {
      id: 248
    },
    ACCOUNT_SUCCESS_REPRESENTATIVE: {
      id: 249
    },
    MTR_VIEW: {
      id: 239
    },
    CV_VIEW: {
      id: 240
    },
  }
};

export const ROLES_STG = {
  INSIGHT_QORE: {
    ADMIN: {
      id: 230
    },
    TEAM_MEMBER: {
      id: 231
    },
    TEAM_MANAGER: {
      id: 232
    },
    ACCOUNT_ASSISTANT: {
      id: 233
    },
    CRO: {
      id: 234
    }
  }
};

export const ROLES_UAT = {
  INSIGHT_QORE: {
    ADMIN: {
      id: 159
    },
    TEAM_MEMBER: {
      id: 160
    },
    TEAM_MANAGER: {
      id: 161
    },
    ACCOUNT_ASSISTANT: {
      id: 162
    },
    CRO: {
      id: 163
    },
    SALES_ACCOUNT_EXECUTIVE: {
      id: 176
    },
    KEY_ACCOUNT_EXECUTIVE: {
      id: 177
    },
    CUSTOMER_SUCCESS_EXECUTIVE: {
      id: 178
    },
    BUSINESS_DEVELOPMENT_EXECUTIVE: {
      id: 179
    },
    ACCOUNT_SUCCESS_REPRESENTATIVE: {
      id: 180
    },
    MTR_VIEW: {
      id: 167
    },
    CV_VIEW: {
      id: 168
    },
  }
};

export const ROLES = {
  INSIGHT_QORE: {
    ADMIN: {
      id: 137
    },
    TEAM_MEMBER: {
      id: 138
    },
    TEAM_MANAGER: {
      id: 139
    },
    ACCOUNT_ASSISTANT: {
      id: 140
    },
    CRO: {
      id: 141
    }
  }
};
export const getRolesByEnv = () => {
  const url = document.location.host;


  if (url.includes('localhost')) {
    return ROLES_DEV;
  } else if (url.includes('develop') || url.includes('dev')) {
    return ROLES_DEV;
  } else if (url.includes('staging') || url.includes('stg')) {
    return ROLES_STG;
  } else if (url.includes('uat') || url.includes('uat')) {
    return ROLES_UAT;
  } else {
    return ROLES;
  }

}
export default (userDefinedRoles, role) => {
  let hasRoleAccess = find(userDefinedRoles, function (obj) {
    return obj.id === role.id;
  });
  return hasRoleAccess != undefined;
};
