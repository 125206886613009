import httpRequestHandler, {
    CancelToken,
    isCancel,
} from '../utils/httpRequestHandler';
import cacheService from './cacheService';

class InsightQoreService {
    /** @typedef {import('../services/cacheService').CacheService} CacheService */

    /** @type {CacheService} */
    #cacheService;

    #cancelTokens = [];

    /**
     * @param {CacheService} cacheService
     */
    constructor(cacheService) {
        this.#cacheService = cacheService;
    }

    setAxiosInterceptors = ({ onLogout }) => {
        httpRequestHandler.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    this.setSession(null);

                    if (onLogout) {
                        onLogout();
                    }
                }

                return Promise.reject(error);
            }
        );
    };

    setSession = accessToken => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            httpRequestHandler.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete httpRequestHandler.defaults.headers.common.Authorization;
        }
    };

    getAccessToken = () => localStorage.getItem('accessToken');

    getCommissionVouchersBySalesPerson = async (
        salesperson,
        month,
        year,
        override = null
    ) => {
        const { data } = override
            ? await httpRequestHandler.get(
                `/commission-voucher/commission-report/${salesperson}/${month}/${year}?override=${override}`
            )
            : await httpRequestHandler.get(
                `/commission-voucher/commission-report/${salesperson}/${month}/${year}`
            );
        return data;
    };

    getGetTieredCommissionSummaryBySalesRep = async (
        salesperson,
        month,
        year,
        atQuota,
        yoY
    ) => {
        const { data } = await httpRequestHandler.get(
            `/commission-voucher/tiered-commission-summary/${salesperson}/${month}/${year}?atquota=${atQuota}&yoy=${yoY}`
        );
        return data;
    };

    getCommissionVouchersDataWithSalesPeriodsAndSalesMembers = async (
        view,
        teamMember
    ) => {
        let result = [];
        if (teamMember) {
            let { data } = await httpRequestHandler.get(
                `/commission-voucher/commission-report-sales-periods/${view}/${teamMember}`
            );
            result = data;
        } else {
            let { data } = await httpRequestHandler.get(
                `/commission-voucher/commission-report-sales-periods/${view}`
            );
            result = data;
        }

        return result;
    };

    getRoofTopSales = async (roofTopId, month, year) => {
        const { data } = await httpRequestHandler.get(
            `/commission-voucher/rooftop-sales/${roofTopId}/${month}/${year}`
        );
        return data;
    };

    getRoofTopSalesBde = async (roofTopId, month, year, salesPerson) => {
        const { data } = await httpRequestHandler.get(
            `/commission-voucher/rooftop-sales-bde/${roofTopId}/${month}/${year}?salesperson=${salesPerson}`
        );
        return data;
    };

    getRoofTopSalesByProduct = async (roofTopId, month, year) => {
        const { data } = await httpRequestHandler.get(
            `/commission-voucher/rooftop-sales-by-product/${roofTopId}/${month}/${year}`
        );
        return data;
    };

    getCommissionHistory = async brokerCode => {
        const { data } = await httpRequestHandler.get(
            `/commission-voucher/commission-history/${brokerCode}`
        );
        return data;
    };

    updateCommission = async adjustment => {
        const { data } = await httpRequestHandler.post(
            '/commission-voucher/commission-adjustment',
            adjustment
        );
        return data;
    };

    //#region Sales Team Management
    getAssociatedMembers = async fullName => {
        const { data } = await httpRequestHandler.get(
            `/sales-team-management/asr-associate-fullname/${fullName}`
        );
        return data;
    };

    getSalesAgentRoles = async name => {
        let res = await httpRequestHandler.get(
            '/sales-team-management/sales-roles'
        );
        return res.data;
    };

    getSalesAgentCommissionTiers = async name => {
        let res = await httpRequestHandler.get(
            `/sales-team-management/sales-commission-tier/${name}`
        );
        return res.data;
    };

    memberHasCommissionData = async teamMemberName => {
        try {
            let res = await httpRequestHandler.get(
                `/sales-team-management/has-commission/${teamMemberName}`
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    changeTiers = async (teamMemberId, tier) => {
        try {
            let res = await httpRequestHandler.post(
                '/sales-team-management/change-tier',
                { teamMemberId, tier }
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    getSalesAgentCommissionTiersById = async teamMemberId => {
        try {
            let res = await httpRequestHandler.get(
                `/sales-team-management/commission-tiers/${teamMemberId}`
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    getSalesTeamMemeberAttributeByFullName = async teamMemberFullName => {
        try {
            let res = await httpRequestHandler.get(
                `/sales-team-management/team-member-attribute/${teamMemberFullName}`
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    getAssociatedTeamMembersByTeamMemberId = async teamMemberId => {
        try {
            let res = await httpRequestHandler.get(
                `/sales-team-management/associates/${teamMemberId}`
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    getAssociatedTeamMemberById = async teamMemberId => {
        try {
            let res = await httpRequestHandler.get(
                `/sales-team-management/asr-associate/${teamMemberId}`
            );
            return res.data;
        } catch (error) {
            console.log(error.message);
        }
    };

    getSalesTeamAttributes = async name => {
        let res = await httpRequestHandler.get(
            '/sales-team-management/team-member'
        );
        if (name) {
            res = await httpRequestHandler.get(
                `/sales-team-management/team-member/${name}`
            );
        }
        return res.data;
    };

    getSalesAgentCommissionOverrides = async childId => {
        let res = await httpRequestHandler.get(
            `/sales-team-management/sales-commission-overrides/${childId}`
        );
        return res.data;
    };

    createUpdateSalesTeamAttributes = async teamMemberData => {
        let res = await httpRequestHandler.post(
            '/sales-team-management/sales-agent',
            teamMemberData
        );

        return res.data;
    };

    createUpdateTeamMemberTier = async tierData => {
        let res = await httpRequestHandler.post(
            '/sales-team-management/sales-commission-tier',
            tierData
        );

        return res.data;
    };

    removeAssociatedTeamMember = async teamMemberData => {
        let res = await httpRequestHandler.delete(
            `/sales-team-management/asr-associate/${teamMemberData.parentSalesTeamId}/${teamMemberData.childSalesTeamId}`
        );

        return res.data;
    };

    removeTeamMemberTier = async tierData => {
        let res = await httpRequestHandler.delete(
            `/sales-team-management/sales-commission-tier/${tierData.salesCommissionsGroupId}/${tierData.salesTeamId}`
        );

        return res.data;
    };
    //#endregion

    //#region Monthly Territory Review

    getMtrRevenueSummary = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        try {
            const { data } = await httpRequestHandler.get(
                `/monthly-territory-review/revenue-summary/${salesperson}/${year}/${month}`
            );
            return data;
        } catch (error) {
            return [];
        }
    };

    getMtrRevenueSummary_bde = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        try {
            const { data } = await httpRequestHandler.get(
                `/monthly-territory-review/revenue-summary-bde/${salesperson}/${year}/${month}`
            );
            return data;
        } catch (error) {
            return [];
        }
    };

    getMtrRevenue = async (
        salesperson,
        month,
        year,
        view = '',
        viewId = '',
        agentId = ''
    ) => {
        if (salesperson === undefined) return [];

        try {
            const { data } = await httpRequestHandler.get(
                `/monthly-territory-review/revenue/${salesperson}/${year}/${month}?view=${view}&viewId=${viewId}&agentId=${agentId}`
            );
            return data;
        } catch (error) {
            return [];
        }
    };

    getMtrRevenue_bde = async (
        salesperson,
        month,
        year,
        view = '',
        viewId = '',
        agentId = ''
    ) => {
        if (salesperson === undefined) return [];

        try {
            const { data } = await httpRequestHandler.get(
                `/monthly-territory-review/revenue-bde/${salesperson}/${year}/${month}?view=${view}&viewId=${viewId}&agentId=${agentId}`
            );
            return data;
        } catch (error) {
            return [];
        }
    };

    getMtrContractCounts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(
            `/monthly-territory-review/contract-count/${salesperson}/${year}/${month}`
        );
        return data;
    };
    getMtrCancelCounts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(
            `/monthly-territory-review/cancel-count/${salesperson}/${year}/${month}`
        );
        return data;
    };
    getMtrCustomerCosts = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(
            `/monthly-territory-review/customer-cost/${salesperson}/${year}/${month}`
        );
        return data;
    };
    getMtrDealerRetained = async (salesperson, month, year) => {
        if (salesperson === undefined) return [];
        const { data } = await httpRequestHandler.get(
            `/monthly-territory-review/dealer-retained/${salesperson}/${year}/${month}`
        );
        return data;
    };

    generateCSV = async (
        salesperson,
        month,
        year,
        view = 'NOT-SET',
        brokerCode = 'NOT-SET'
    ) => {
        if (salesperson === undefined) return [];

        try {
            const { data } = await httpRequestHandler.get(
                `/monthly-territory-review/export/${salesperson}/${year}/${month}?view=${view}&brokerCode=${brokerCode}`
            );

            const link = document.createElement('a');

            link.href = data.blobUri;
            link.download = data.blobName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            return [];
        }
    };


    //#endregion

    /**
     * @param {import('axios').AxiosError} error
     * @returns {boolean}
     */
    isCancelError = error => isCancel(error);
}

const claimService = new InsightQoreService(cacheService);
claimService.setSession(claimService.getAccessToken());

export default claimService;
