import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
    ThemeProvider,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    Auth,
    CacheProvider,
    PORTAL_TYPE,
    Routes,
    ScrollReset,
    setApplicationDetails,
    useInitialTheme,
    setUserData,
} from '@nsd/fe';
import insightQoreService from 'src/services/insightQoreService';
import hasAccess, { getRolesByEnv } from 'src/utils/hasAccess';
import apps from 'src/apps';
import Chart from 'chart.js/auto'; //DO NOT REMOVE
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale } from 'chart.js';


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);
const APP_VERSION = process.env.REACT_APP_VERSION;
const APP_NAME = 'Insight Qore';
const APP_END_USER = PORTAL_TYPE.EMPLOYEE;
/* Used to determine loading/splash screen logo
Choices for APP_INTERNAL_NAME
[
  "dispatch-qore",
  "lender-qore",
  "client-qore",
  "provider-qore",
  "claims-qore",
  "contact-qore",
  "insight-qore",
  "sales-qore",
  "marketing-qore",
  "market-qore",
  "developer-qore",
  "pricebook-qore",
  "cash-qore",
  "integration-qore",
  "driver-qore",
  "billing-qore",
  "cash-qore",
  "dealer-qore",
  "licensing-qore",
  "cash-qore",
  "data-qore",
  "notifications-qore"
]
*/
const APP_INTERNAL_NAME = 'insight-qore';


const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                width: '100%',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
        },
    })
);

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setApplicationDetails(
                APP_END_USER,
                APP_NAME,
                APP_VERSION,
                APP_INTERNAL_NAME
            )
        );
    }, [dispatch]);

    const { user, appSettings } = useSelector(state => state.account);

    let [hasLoadedInitialMembers, setHasLoadedInitialMembers] = useState(false);
    useEffect(() => {
        (async () => {
            if (user && !hasLoadedInitialMembers) {
                setHasLoadedInitialMembers(true);
                let associatedTeamMembers = [];
                let teamMemberOverride = null;

                if (hasAccess(user.roles, getRolesByEnv().INSIGHT_QORE.ACCOUNT_SUCCESS_REPRESENTATIVE)) {

                    associatedTeamMembers = await insightQoreService.getAssociatedMembers(
                        user.fullName
                    );

                    if (associatedTeamMembers.length > 0) {
                        teamMemberOverride = associatedTeamMembers[0];
                    }
                }

                let isBdeSalesRep = false
                if (hasAccess(user.roles, getRolesByEnv().INSIGHT_QORE.TEAM_MEMBER)) {

                    const teamMemberAttributes = await insightQoreService.getSalesTeamMemeberAttributeByFullName(
                        user.fullName
                    );
                    if (teamMemberAttributes) {
                        let associatedTeamMembers = await insightQoreService.getAssociatedTeamMemberById(
                            teamMemberAttributes.salesTeamId
                        );
                        if (associatedTeamMembers && associatedTeamMembers.length > 0) {
                            isBdeSalesRep = true;
                        }

                    }
                }

                await dispatch(
                    setUserData(
                        { ...user, associatedTeamMembers, teamMemberOverride, isBdeSalesRep },
                        appSettings,
                        user.roles,
                        null
                    )
                );

            }
        })();
    }, [user]);



    useStyles();
    const { theme } = useInitialTheme();

    if (!theme) return null;
    return (
        <CacheProvider>
            <DndProvider backend={HTML5Backend}>
                <ThemeProvider theme={theme}>
                    <StylesProvider jss={jss}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <SnackbarProvider maxSnack={1}>
                                <Router history={history}>
                                    <Auth>
                                        <ScrollReset />
                                        {/* Make the link here open the settings widget? */}
                                        {/* <SettingsNotification /> */}
                                        <Routes
                                            apps={apps || []}
                                        />
                                    </Auth>
                                </Router>
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </StylesProvider>
                </ThemeProvider>
            </DndProvider>
        </CacheProvider>
    );
}

export default App;

